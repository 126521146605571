<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-card-text>
        <h1 class="p-2">Plan: {{ PlansAllowed }}</h1>
      </b-card-text>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostrar</label>
            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>registros</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-col cols="12" md="9">
                <div class="d-flex align-items-center justify-content-end">
                  <b-col md="12" class="offset-8">
                    <b-input-group size="m">
                      <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Buscar" />
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                          Borrar
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-button variant="primary" @click="(store_tarifa = true, resetFromStore())">
                    <span class="text-nowrap">Nueva tarifa</span>
                  </b-button>
                </div>
              </b-col>
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="d-flex">
              <b-button variant="success" class="btn-icon rounded-circle mr-2" @click="downloadTemplate1()">
                <feather-icon icon="ArrowDownIcon" />
              </b-button>
              <b-form-file v-model="import_rates" drop-placeholder="subir" />
              <b-button variant="success" class="btn-icon rounded-circle ml-1" @click="uploadTemplate()">
                <feather-icon icon="ArrowUpIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table ref="dataTable" class="position-relative" :items="dataTable" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
        :sort-desc.sync="tableSettings.sortDesc" :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered">
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button @click="openModal(data.item)" variant="warning" class="btn-icon rounded-circle ml-2">
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>

      </b-table>
      <b-table ref="dataTable1" class="position-relative mt-5 mb-5" :items="dataTable1" responsive
        :fields="tableColumnsErrors" primary-key="id" show-empty empty-text="No se encontraron datos"
        v-if="showTableErrors">
        <!-- Column: Actions -->


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }}
              registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="tableSettings.page" :total-rows="totalRows" :per-page="tableSettings.perPage"
              first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
    <!-- modal crear tarifa -->
    <b-modal hide-footer title="Crear Tarfia" v-model="store_tarifa">
      <validation-observer ref="formTarifa">
        <b-form>
          <b-row>
            <b-col md="12" xl="12" class="mb-1">
              <validation-provider #default="{ errors }" name="Numero Del Dia" rules="required">
                <b-form-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <label for="day">Numero Del Dia</label>
                  <b-form-input :state="errors.length > 0 ? false : null" v-model="formStore.day" type="number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12" class="mb-1">
              <validation-provider #default="{ errors }" name="Valor" rules="required">
                <b-form-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <label for="Agencia">Valor</label>
                  <b-form-input :state="errors.length > 0 ? false : null" type="number" v-model="formStore.value" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12" class="mb-1">
              <validation-provider #default="{ errors }" name="Upgrade" rules="required">
                <b-form-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <label for="Agencia">Upgrade</label>
                  <b-form-input :state="errors.length > 0 ? false : null" type="number" v-model="formStore.upgrade" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12" class="mb-1 text-center">
              <b-button @click="validationFormTarifa()" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                Crear
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- modal crear tarifa -->
    <!-- modal editar tarifa -->
    <b-modal hide-footer title="Actualizar Tarfia" v-model="update_tarifa">
      <validation-observer ref="formEditTarifa">
        <b-form>
          <b-row>
            <b-col md="12" xl="12" class="mb-1">
              <validation-provider #default="{ errors }" name="Numero Del Dia" rules="required">
                <b-form-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <label for="day">Numero Del Dia</label>
                  <b-form-input :state="errors.length > 0 ? false : null" v-model="form.day" type="number" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12" class="mb-1">
              <validation-provider #default="{ errors }" name="Valor" rules="required">
                <b-form-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <label for="Agencia">Valor</label>
                  <b-form-input :state="errors.length > 0 ? false : null" type="number" v-model="form.value" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12" class="mb-1">
              <validation-provider #default="{ errors }" name="Upgrate" rules="required">
                <b-form-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <label for="Agencia">Upgrade</label>
                  <b-form-input :state="errors.length > 0 ? false : null" type="number" v-model="form.upgrade" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" xl="12" class="mb-1 text-center">
              <b-button @click="validationEditFormTarifa()" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                Actualizar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- modal editar tarifa -->
  </div>
</template>

<script>
extend('required', {
  ...required,
  message: 'El campo {_field_} es obligatorio'
});
import { extend } from 'vee-validate'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BSpinner, BFormFile, BCardText, BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import RateAddNew from './RateAddNew.vue'
import Ripple from 'vue-ripple-directive'
import es from 'vee-validate/dist/locale/es'
export default {
  name: 'Rates',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    RateAddNew,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BSpinner,
    BFormFile,
    BCardText,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      PlansAllowed: null,
      filter: null,
      filterOn: [],
      spinnerTemplate: false,
      store_tarifa: false,
      update_tarifa: false,
      id: this.$route.params && this.$route.params.id,
      formDisabled: false,
      refRoleListTable: null,
      perPageOptions: [25, 50, 100],
      searchQuery: '',
      tableColumns: [
        { key: 'day_number', label: 'Día', sortable: true },
        { key: 'plan', label: 'Plan' },
        { key: 'value', label: 'Valor', sortable: true },
        { key: 'upgrade', label: 'Upgrade', sortable: true },
        { key: 'actions', label: 'acciones' },
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      showLoadingTable: false,
      tableSettings: {
        searchQuery: '',
        perPage: 25,
        page: 1,
        sortBy: 'id',
        sortDesc: false,
      },
      formDataEdit: {},
      plans: [],
      formStore: {
        day: '',
        value: '',
        upgrade: '',
        id: this.$route.params && this.$route.params.id,
      },
      form: {
        day: '',
        value: '',
        upgrade: '',
        id: this.$route.params && this.$route.params.id,
        id_tarifa: ''
      },
      formRate: {
        id: this.$route.params && this.$route.params.id,
      },
      import_rates: null,
      errorsRates: [],
      showTableErrors: false
    }
  },
  directives: {
    Ripple,
  },
  watch: {
    "tableSettings.sortBy": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.sortDesc": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.perPage": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.searchQuery": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.page": {
      handler(val) {
        this.fetchData()
      },
    },
  },
  created() {
    this.planes();
    this.fetchData();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    downloadTemplate1() {
      let url = '/plans/downloadRates/' + this.id
      this.$http({ method: 'post', url, responseType: 'arraybuffer' })
        .then((response) => {
          this.forceFileDownload(response, name)
        }).catch(() => console.log('error occured'))
    },
    forceFileDownload(response, name) {
      var fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileUrl;
      fileLink.setAttribute('download', 'tarifas.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    uploadTemplate() {
      let file = new FormData();
      file.append('file', this.import_rates)
      this.$http.post('/plans/uploadRates/' + this.id, file)
        .then(response => {
          if (response.data.message == 'Registros subidos y creados con exito') {
            this.fetchData();
            this.import_rates = null
            this.$swal({
              title: 'Archivo cargado con exito',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          }
          if (response.data.errors.length > 0) {
            this.dataTable1 = response.data.errors
            this.errorsRates = response.data.errors
            this.$swal({
              title: 'Error Al Cargar El Archivo',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            });
            this.showTableErrors = true
          }
          if (response.data.code == 500) {
            this.$swal({
              title: response.data.message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    resetFromStore() {
      this.formStore = {
        day: '',
        value: '',
        upgrade: '',
        id: this.$route.params && this.$route.params.id,
      }
    },
    openModal(object) {
      this.form.id_tarifa = object.id;
      this.update_tarifa = true;
      this.form.day = object.day_number;
      this.form.value = object.value;
      this.form.upgrade = object.upgrade;
    },
    validationFormTarifa() {
      localize('es', es);
      this.$refs.formTarifa.validate().then(success => {
        if (success) {
          this.newTarifa();
        }
      })
    },
    validationEditFormTarifa() {
      localize('es', es);
      this.$refs.formEditTarifa.validate().then(success => {
        if (success) {
          this.update_tarifa_id();
        }
      })
    },
    newTarifa() {
      this.$http.post('/plans/tarifa/create/' + this.id, this.formStore)
        .then(response => {
          if (response.data.code == 200) {
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.formStore = {
              day: '',
              value: '',
              upgrade: '',
              id: ''
            }
            this.store_tarifa = false;
            this.$refs.formTarifa.reset();
            this.fetchData();
          }
          if (response.data.code == 400) {
            this.$swal({
              title: response.data.message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            });
          }
          if (response.data.code == 500) {
            this.$swal({
              title: response.data.message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update_tarifa_id() {
      this.$http.post('/plans/tarifa/update/' + this.id, this.form)
        .then(response => {
          if (response.data.code == 200) {
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.form = {
              day: '',
              value: '',
              upgrade: '',
              id: '',
              id_tarifa: ''
            }
            this.update_tarifa = false;
            this.$refs.formEditTarifa.reset();
            this.fetchData();
          }
          if (response.data.code == 500) {
            this.$swal({
              title: response.data.message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    planes() {
      this.$http.get('/plans/list').then((response) => {
        this.plans = response.data.plans
      })
    },
    fetchData() {
      this.showLoadingTable = true
      this.$http.get('/plans/rates/' + this.id, { params: this.tableSettings }).then((response) => {
        this.dataTable = response.data.rates
        this.totalRows = response.data.total
        this.dataMetaCounter()
        this.showLoadingTable = false
        this.PlansAllowed = this.plans.find(obj => obj.id == this.id).name
      })
    },
    dataMetaCounter() {
      const localItemsCount = this.dataTable.length
      this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
      this.dataMeta.of = this.totalRows
    },
    detailData(row) {
      this.formDisabled = true
      this.formDataEdit = row
    },
    editData(row) {
      this.formDataEdit = row
    },
    deleteData(row) {
      this.$swal({
        title: `Esta séguro de eliminar el plan ${row.name}?`,
        text: "Este proceso no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('/plans/delete/' + row.id).then(() => {
            this.fetchData()
            this.$swal({
              icon: 'success',
              title: 'Plan eliminado!',
              text: 'El plan ha sido eliminado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Eliminación cancelada',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    downloadTemplate() {
      this.spinnerTemplate = true
      this.$http.post('/plans/rates/download-template', this.formRate, { responseType: 'blob' }).then((response) => {
        this.spinnerTemplate = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'platilla cargar tarifas.xlsx')
        document.body.appendChild(link)
        link.click()
        this.$swal({
          icon: "success",
          title: "Éxito",
          html: "Archivo descargado éxitosamente!",
        })
      })
        .catch((error) => {
          this.spinnerTemplate = false
          this.$swal({
            icon: "error",
            title: "Error",
            html: "Ha ocurrido un error, por favor intenta de nuevo o comunicate con el administrador.",
          })
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
